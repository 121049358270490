/* Preloader Area Style One
============================================*/
.preloader-style-one {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #5b5bff;
  top: 0;
  left: 0;
  z-index: 99999;
}
.preloader-style-one .lds-ripple {
  position: relative;
  width: 55px;
  height: 55px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.preloader-style-one .lds-ripple div {
  position: absolute;
  border: 2px dashed #ffffff;
  opacity: 1;
  -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
          animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.preloader-style-one .lds-ripple div:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.preloader-style-one::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 60%;
  z-index: -1;
  background: #5b5bff;
  transition: all ease 0.5s;
}
.preloader-style-one::after {
  left: auto;
  right: 0;
}
.preloader-style-one.preloader-deactivate {
  visibility: hidden;
}
.preloader-style-one.preloader-deactivate::after {
  width: 0;
}
.preloader-style-one.preloader-deactivate::before {
  width: 0;
}
.preloader-style-one.preloader-deactivate .loader {
  opacity: 0;
  visibility: hidden;
}

@-webkit-keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
/* Preloader Area Style Two
============================================*/
.preloader-style-two {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.preloader-style-two .main-circle {
  width: 150px;
  height: 150px;
  border: 4px solid blue;
  border-top: 4px solid transparent;
  border-right: 4px solid transparent;
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-animation: rotate-two 2s infinite;
          animation: rotate-two 2s infinite;
}
.preloader-style-two .main-circle::before {
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  border: 4px solid transparent;
  border-right: 4px solid blue;
  transform: rotate(40deg);
  border-radius: 50%;
}
.preloader-style-two .main-circle .green-circle {
  -webkit-animation: rotate-two 2s infinite 0.4s;
          animation: rotate-two 2s infinite 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border: 4px solid blue;
  border-top: 4px solid transparent;
  border-right: 4px solid transparent;
  transform: rotate(-20deg);
  border-radius: 50%;
  position: relative;
}
.preloader-style-two .main-circle .green-circle::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  border: 4px solid transparent;
  border-right: 4px solid blue;
  transform: rotate(60deg);
}
.preloader-style-two .main-circle .green-circle .brown-circle {
  -webkit-animation: rotate-two 2s infinite 0.6s;
          animation: rotate-two 2s infinite 0.6s;
  width: 80px;
  height: 80px;
  border: 4px solid blue;
  border-top: 4px solid transparent;
  border-right: 4px solid transparent;
  transform: rotate(-20deg);
  border-radius: 50%;
}

@-webkit-keyframes rotate-two {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotate-two {
  to {
    transform: rotate(360deg);
  }
}
/* Preloader Area Style Three
============================================*/
.preloader-style-three {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #5b5bff;
}
.preloader-style-three .preloader-wrap {
  width: 80px;
  height: 80px;
  position: relative;
  transform: rotate(45deg);
  -webkit-animation: walk-three 1s linear infinite;
          animation: walk-three 1s linear infinite;
}
.preloader-style-three .preloader-wrap span {
  width: 40px;
  height: 40px;
  background: #FFB037;
  position: absolute;
  -webkit-animation: spin-three 1s linear infinite;
          animation: spin-three 1s linear infinite;
}
.preloader-style-three .preloader-wrap span:nth-child(1) {
  top: 0;
  left: 0;
}
.preloader-style-three .preloader-wrap span:nth-child(2) {
  top: 0;
  right: 0;
}
.preloader-style-three .preloader-wrap span:nth-child(3) {
  bottom: 0;
  left: 0;
}
.preloader-style-three .preloader-wrap span:nth-child(4) {
  bottom: 0;
  right: 0;
}
.preloader-style-three .preloader-wrap .light {
  background: #FFEAA5;
}

@-webkit-keyframes walk-three {
  50% {
    width: 130px;
    height: 130px;
  }
}

@keyframes walk-three {
  50% {
    width: 130px;
    height: 130px;
  }
}
@-webkit-keyframes spin-three {
  0% {
    transform: rotate(0);
  }
  50%, 90%, 100% {
    transform: rotate(90deg);
  }
}
@keyframes spin-three {
  0% {
    transform: rotate(0);
  }
  50%, 90%, 100% {
    transform: rotate(90deg);
  }
}
/* Preloader Area Style Four
============================================*/
.preloader-style-four {
  background-color: #000000;
  height: auto;
}
.preloader-style-four .preloader-wrap {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50px, -50px);
}
.preloader-style-four .loader-inner-1 {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(70deg);
}
.preloader-style-four .loader-inner-1:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  position: absolute;
  right: 0;
  -webkit-animation-name: loading-1;
          animation-name: loading-1;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  background: #06aed5;
}
.preloader-style-four .loader-inner-2 {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(160deg);
}
.preloader-style-four .loader-inner-2:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  position: absolute;
  right: 0;
  -webkit-animation-name: loading-1;
          animation-name: loading-1;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  background: #ec008c;
}
.preloader-style-four .loader-inner-3 {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotate(-110deg);
}
.preloader-style-four .loader-inner-3:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  position: absolute;
  right: 0;
  -webkit-animation-name: loading-1;
          animation-name: loading-1;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  background: #ffbf00;
}
.preloader-style-four .loader-inner-4 {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: rotate(-20deg);
}
.preloader-style-four .loader-inner-4:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  position: absolute;
  right: 0;
  -webkit-animation-name: loading-1;
          animation-name: loading-1;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  background: #079c00;
}

@-webkit-keyframes loading-1 {
  0% {
    width: 20px;
    right: 0;
  }
  30% {
    width: 120px;
    right: -100px;
  }
  60% {
    width: 20px;
    right: -100px;
  }
}

@keyframes loading-1 {
  0% {
    width: 20px;
    right: 0;
  }
  30% {
    width: 120px;
    right: -100px;
  }
  60% {
    width: 20px;
    right: -100px;
  }
}
/* Preloader Area Style Five
============================================*/
.preloader-style-five {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.preloader-style-five .content {
  width: 80px;
  height: 80px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.preloader-style-five .content::after {
  content: "";
  position: absolute;
  width: 90%;
  height: 90%;
  background: white;
}
.preloader-style-five .content::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  -webkit-animation: rotate-five 12s infinite alternate;
          animation: rotate-five 12s infinite alternate;
}
.preloader-style-five .content .box {
  height: 50%;
  width: 50%;
  background: #000;
  position: relative;
  z-index: 10;
  -webkit-animation: 12s rotate-five infinite alternate;
          animation: 12s rotate-five infinite alternate;
}

@-webkit-keyframes rotate-five {
  0% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-five {
  0% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(360deg);
  }
}
/* Preloader Area Style Six
============================================*/
.preloader-style-six {
  background-color: #000;
}
.preloader-style-six .preloader-container {
  position: absolute;
  width: 80px;
  height: 80px;
  -webkit-animation: spin-six 2.5s infinite cubic-bezier(0.17, 0.72, 0.55, 1.66);
          animation: spin-six 2.5s infinite cubic-bezier(0.17, 0.72, 0.55, 1.66);
  left: 50%;
  top: 50%;
  transform: translate(-50px, -50px);
}
.preloader-style-six .ball {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 11%;
  height: 50%;
  background-color: transparent;
  transform-origin: bottom center;
  overflow: visible;
}
.preloader-style-six .ball:nth-of-type(2) {
  transform: translate(-50%, 0) rotate(36deg);
}
.preloader-style-six .ball:nth-of-type(3) {
  transform: translate(-50%, 0) rotate(72deg);
}
.preloader-style-six .ball:nth-of-type(4) {
  transform: translate(-50%, 0) rotate(108deg);
}
.preloader-style-six .ball:nth-of-type(5) {
  transform: translate(-50%, 0) rotate(144deg);
}
.preloader-style-six .ball:nth-of-type(6) {
  transform: translate(-50%, 0) rotate(180deg);
}
.preloader-style-six .ball:nth-of-type(7) {
  transform: translate(-50%, 0) rotate(216deg);
}
.preloader-style-six .ball:nth-of-type(8) {
  transform: translate(-50%, 0) rotate(252deg);
}
.preloader-style-six .ball:nth-of-type(9) {
  transform: translate(-50%, 0) rotate(288deg);
}
.preloader-style-six .ball:nth-of-type(10) {
  transform: translate(-50%, 0) rotate(324deg);
}
.preloader-style-six .ball::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 56%;
  border-radius: 70px;
  background-color: #ef1144;
}
.preloader-style-six .ball::after {
  position: absolute;
  content: "";
  bottom: -100;
  left: 0;
  width: 100%;
  height: 56%;
  border-radius: 70px;
  background-color: #ffc7bd;
  -webkit-animation: opacity-six 2.5s infinite linear;
          animation: opacity-six 2.5s infinite linear;
  opacity: 0;
}

@-webkit-keyframes spin-six {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin-six {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes opacity-six {
  20% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes opacity-six {
  20% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* Preloader Area Style Seven
============================================*/
.preloader-style-seven {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.preloader-style-seven ::before {
  position: absolute;
  content: "";
  box-sizing: border-box;
}
.preloader-style-seven .preloader-wrap .preloader-container {
  width: 80px;
  height: 80px;
}
.preloader-style-seven .preloader-wrap .ball {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 15%;
  height: 50%;
  background-color: transparent;
  transform-origin: bottom center;
  overflow: visible;
}
.preloader-style-seven .preloader-wrap .ball::before {
  top: 0;
  left: 0;
  width: 67%;
  height: 60%;
  border-radius: 50%;
  background-color: #ef1144;
  -webkit-animation: opacity-seven 1.2s infinite linear;
          animation: opacity-seven 1.2s infinite linear;
  opacity: 0.1;
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(2) {
  transform: translate(-50%, 0) rotate(30deg);
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(2)::before {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(3) {
  transform: translate(-50%, 0) rotate(60deg);
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(3)::before {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(4) {
  transform: translate(-50%, 0) rotate(90deg);
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(4)::before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(5) {
  transform: translate(-50%, 0) rotate(120deg);
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(5)::before {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(6) {
  transform: translate(-50%, 0) rotate(150deg);
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(6)::before {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(7) {
  transform: translate(-50%, 0) rotate(180deg);
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(7)::before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(8) {
  transform: translate(-50%, 0) rotate(210deg);
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(8)::before {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(9) {
  transform: translate(-50%, 0) rotate(240deg);
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(9)::before {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(10) {
  transform: translate(-50%, 0) rotate(270deg);
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(10)::before {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(11) {
  transform: translate(-50%, 0) rotate(300deg);
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(11)::before {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(12) {
  transform: translate(-50%, 0) rotate(330deg);
}
.preloader-style-seven .preloader-wrap .ball:nth-of-type(12)::before {
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

@-webkit-keyframes opacity-seven {
  0% {
    opacity: 1;
  }
  85% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes opacity-seven {
  0% {
    opacity: 1;
  }
  85% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.1;
  }
}
/* Preloader Area Style Eight
============================================*/
.preloader-style-eight {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.preloader-style-eight ::before {
  position: absolute;
  content: "";
  box-sizing: border-box;
}
.preloader-style-eight .preloader-wrap .preloader-container {
  width: 80px;
  height: 80px;
}
.preloader-style-eight .preloader-wrap .ball {
  position: absolute;
  top: 0;
  left: 50%;
  width: 15%;
  height: 50%;
  background-color: transparent;
  transform-origin: bottom center;
  overflow: visible;
  -webkit-animation: rotate-eight 5s infinite linear;
          animation: rotate-eight 5s infinite linear;
}
.preloader-style-eight .preloader-wrap .ball::before {
  top: 0;
  left: 0;
  width: 80%;
  padding-bottom: 80%;
  border-radius: 50%;
  background-color: #ef1144;
}
.preloader-style-eight .preloader-wrap .ball:nth-of-type(2) {
  -webkit-animation-delay: 0.15s;
          animation-delay: 0.15s;
}
.preloader-style-eight .preloader-wrap .ball:nth-of-type(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.preloader-style-eight .preloader-wrap .ball:nth-of-type(4) {
  -webkit-animation-delay: 0.45s;
          animation-delay: 0.45s;
}
.preloader-style-eight .preloader-wrap .ball:nth-of-type(5) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.preloader-style-eight .preloader-wrap .ball:nth-of-type(6) {
  -webkit-animation-delay: 0.75s;
          animation-delay: 0.75s;
}
.preloader-style-eight .preloader-wrap .ball:nth-of-type(7) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
.preloader-style-eight .preloader-wrap .ball:nth-of-type(8) {
  -webkit-animation-delay: 1.05s;
          animation-delay: 1.05s;
}
.preloader-style-eight .preloader-wrap .ball:nth-of-type(9) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
.preloader-style-eight .preloader-wrap .ball:nth-of-type(10) {
  -webkit-animation-delay: 1.35s;
          animation-delay: 1.35s;
}
.preloader-style-eight .preloader-wrap .ball:nth-of-type(11) {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

@-webkit-keyframes rotate-eight {
  0% {
    transform: translate(-50%, 0%) rotate(0deg);
  }
  40% {
    transform: translate(-50%, 0%) rotate(360deg);
  }
  60% {
    transform: translate(-50%, 0%) rotate(520deg);
  }
  90% {
    transform: translate(-50%, 0%) rotate(590deg);
  }
  100% {
    transform: translate(-50%, 0%) rotate(720deg);
  }
}

@keyframes rotate-eight {
  0% {
    transform: translate(-50%, 0%) rotate(0deg);
  }
  40% {
    transform: translate(-50%, 0%) rotate(360deg);
  }
  60% {
    transform: translate(-50%, 0%) rotate(520deg);
  }
  90% {
    transform: translate(-50%, 0%) rotate(590deg);
  }
  100% {
    transform: translate(-50%, 0%) rotate(720deg);
  }
}
/* Preloader Area Style Nine
============================================*/
.preloader-style-nine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.preloader-style-nine ::before {
  position: absolute;
  content: "";
  box-sizing: border-box;
}
.preloader-style-nine .preloader-wrap .preloader-container {
  width: 80px;
  height: 80px;
}
.preloader-style-nine .preloader-wrap .petal {
  position: absolute;
  width: 30%;
  height: 50%;
  bottom: 50%;
  left: 50%;
  transform-origin: bottom center;
  border-radius: 50%;
  z-index: 2;
}
.preloader-style-nine .preloader-wrap .petal::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  bottom: 0%;
  left: 0%;
  transform-origin: bottom center;
  border-radius: 50%;
  background-color: #ef1144;
  z-index: 2;
  -webkit-animation: opacity-nine 0.8s infinite linear;
          animation: opacity-nine 0.8s infinite linear;
  opacity: 1;
  border-left: 1px solid #ffffff;
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(6) {
  transform: translate(-50%, 0%) rotate(225deg);
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(6)::before {
  background-color: transparent;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(7) {
  transform: translate(-50%, 0%) rotate(270deg);
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(7)::before {
  background-color: transparent;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(8) {
  transform: translate(-50%, 0%) rotate(315deg);
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(8)::before {
  background-color: transparent;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(1) {
  transform: translate(-50%, 0%);
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(1)::before {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(2) {
  transform: translate(-50%, 0%) rotate(45deg);
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(2)::before {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(3) {
  transform: translate(-50%, 0%) rotate(90deg);
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(3)::before {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(4) {
  transform: translate(-50%, 0%) rotate(135deg);
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(4)::before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(5) {
  transform: translate(-50%, 0%) rotate(180deg);
}
.preloader-style-nine .preloader-wrap .petal:nth-of-type(5)::before {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.preloader-style-nine .preloader-wrap .petal-1 {
  position: absolute;
  width: 30%;
  height: 50%;
  bottom: 50%;
  left: 50%;
  transform-origin: bottom center;
  border-radius: 50%;
  z-index: -1;
}
.preloader-style-nine .preloader-wrap .petal-1::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  bottom: 0%;
  left: 0%;
  transform-origin: bottom center;
  border-radius: 50%;
  background-color: #ef1144;
  z-index: 2;
  -webkit-animation: opacity-nine 0.8s infinite linear;
          animation: opacity-nine 0.8s infinite linear;
  opacity: 1;
}
.preloader-style-nine .preloader-wrap .petal-1:nth-of-type(9) {
  transform: translate(-50%, 0%) rotate(225deg);
}
.preloader-style-nine .preloader-wrap .petal-1:nth-of-type(9)::before {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.preloader-style-nine .preloader-wrap .petal-1:nth-of-type(10) {
  transform: translate(-50%, 0%) rotate(270deg);
}
.preloader-style-nine .preloader-wrap .petal-1:nth-of-type(10)::before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.preloader-style-nine .preloader-wrap .petal-1:nth-of-type(11) {
  transform: translate(-50%, 0%) rotate(315deg);
}
.preloader-style-nine .preloader-wrap .petal-1:nth-of-type(11)::before {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
.preloader-style-nine .preloader-wrap .ball {
  position: absolute;
  width: 28%;
  height: 28%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 1px solid #ffffff;
  background-color: #ef1144;
  z-index: 3;
}

@-webkit-keyframes opacity-nine {
  0% {
    opacity: 1;
  }
  95% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.2;
  }
}

@keyframes opacity-nine {
  0% {
    opacity: 1;
  }
  95% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.2;
  }
}
/* Preloader Area Style Ten
============================================*/
.preloader-style-ten {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.preloader-style-ten ::before {
  position: absolute;
  content: "";
  box-sizing: border-box;
}
.preloader-style-ten .preloader-container {
  width: 80px;
  height: 80px;
  animation: rotate-ten 3s infinite linear reverse;
}
.preloader-style-ten .preloader-container .ball {
  position: absolute;
  width: 65%;
  height: 65%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 3px solid #ffffff;
  background-color: #ef1144;
  z-index: 5;
}
.preloader-style-ten .preloader-container .petal {
  position: absolute;
  width: 28%;
  height: 50%;
  bottom: 50%;
  left: 50%;
  transform-origin: bottom center;
}
.preloader-style-ten .preloader-container .petal::before {
  position: absolute;
  content: "";
  width: 72%;
  height: 40%;
  left: 50%;
  transform-origin: 0% 0%;
  transform: scale(1, 1.8) rotate(45deg);
  background: #ef1144;
}
.preloader-style-ten .preloader-container .petal::after {
  position: absolute;
  content: "";
  width: 72%;
  height: 40%;
  left: 50%;
  transform-origin: 0% 0%;
  transform: scale(1, 1.8) rotate(45deg);
  background: #ef1144;
  top: 100%;
}
.preloader-style-ten .preloader-container .petal:nth-of-type(1) {
  transform: translate(-50%, 0%) rotate(0deg);
}
.preloader-style-ten .preloader-container .petal:nth-of-type(2) {
  transform: translate(-50%, 0%) rotate(30deg);
}
.preloader-style-ten .preloader-container .petal:nth-of-type(3) {
  transform: translate(-50%, 0%) rotate(60deg);
}
.preloader-style-ten .preloader-container .petal:nth-of-type(4) {
  transform: translate(-50%, 0%) rotate(90deg);
}
.preloader-style-ten .preloader-container .petal:nth-of-type(5) {
  transform: translate(-50%, 0%) rotate(120deg);
}
.preloader-style-ten .preloader-container .petal:nth-of-type(6) {
  transform: translate(-50%, 0%) rotate(150deg);
}

@-webkit-keyframes rotate-ten {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-ten {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Preloader Area Style Eleven
============================================*/
.preloader-style-eleven .preloader-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.preloader-style-eleven .loader-circle {
  background-color: transparent;
  border: 5px solid rgb(30, 30, 30);
  opacity: 0.9;
  border-right: 5px solid #3694FF;
  border-left: 5px solid #3694FF;
  border-radius: 50px;
  box-shadow: 0 0 35px #3694FF;
  width: 80px;
  height: 80px;
  -webkit-animation: spinPulse-eleven 1s infinite ease-in-out;
          animation: spinPulse-eleven 1s infinite ease-in-out;
  -webkit-animation: spinPulse-eleven 1s infinite linear;
          animation: spinPulse-eleven 1s infinite linear;
}
.preloader-style-eleven .loader-circle1 {
  background-color: rgba(0, 0, 0, 0);
  border: 5px solid rgb(30, 30, 30);
  opacity: 0.9;
  border-left: 5px solid #3694FF;
  border-right: 5px solid #3694FF;
  border-radius: 50px;
  box-shadow: 0 0 2px #3694FF, inset 0 0 2px #3694FF;
  width: 60px;
  height: 60px;
  position: relative;
  top: -80px;
  left: 10px;
  -webkit-animation: spinoffPulse-eleven 1s infinite linear;
          animation: spinoffPulse-eleven 1s infinite linear;
}

@-webkit-keyframes spinoffPulse-eleven {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinoffPulse-eleven {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinPulse-eleven {
  0% {
    transform: rotate(160deg);
    opacity: 0;
    box-shadow: 0 0 1px #fff;
  }
  50% {
    transform: rotate(145deg);
    opacity: 1;
  }
  100% {
    transform: rotate(-320deg);
    opacity: 0;
  }
}
@keyframes spinPulse-eleven {
  0% {
    transform: rotate(160deg);
    opacity: 0;
    box-shadow: 0 0 1px #fff;
  }
  50% {
    transform: rotate(145deg);
    opacity: 1;
  }
  100% {
    transform: rotate(-320deg);
    opacity: 0;
  }
}
@keyframes spinoffPulse-eleven {
  0% {
    transform: rotate(-160deg);
    opacity: 0;
    box-shadow: 0 0 0px #fff, inset 0 0 0px #fff;
  }
  50% {
    transform: rotate(-145deg);
    opacity: 1;
  }
  100% {
    transform: rotate(320deg);
    opacity: 0;
  }
}
/* Preloader Area Style Twelve
============================================*/
.preloader-style-twelve .preloader-wrap {
  position: absolute;
  box-sizing: border-box;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.preloader-style-twelve span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  -webkit-animation: anime 4s infinite;
          animation: anime 4s infinite;
}
.preloader-style-twelve span:nth-child(1) {
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
}
.preloader-style-twelve span:nth-child(2) {
  -webkit-animation-delay: 3.5s;
          animation-delay: 3.5s;
}
.preloader-style-twelve span:nth-child(3) {
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
}
.preloader-style-twelve span:nth-child(4) {
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}
.preloader-style-twelve span:nth-child(5) {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}
.preloader-style-twelve span:nth-child(6) {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}
.preloader-style-twelve span:nth-child(7) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
.preloader-style-twelve span:nth-child(8) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

@-webkit-keyframes anime {
  0% {
    width: 0px;
    height: 0px;
    box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0);
  }
  20% {
    box-shadow: inset 0px 5px 60px 15px rgba(255, 255, 255, 0.4);
  }
  40% {
    box-shadow: inset 0px 5px 60px 15px rgba(255, 255, 255, 0.2);
  }
  100% {
    width: 200px;
    height: 200px;
    box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0);
  }
}

@keyframes anime {
  0% {
    width: 0px;
    height: 0px;
    box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0);
  }
  20% {
    box-shadow: inset 0px 5px 60px 15px rgba(255, 255, 255, 0.4);
  }
  40% {
    box-shadow: inset 0px 5px 60px 15px rgba(255, 255, 255, 0.2);
  }
  100% {
    width: 200px;
    height: 200px;
    box-shadow: inset 0 0 0 0px rgba(0, 0, 0, 0);
  }
}
/* Preloader Area Style Thirteen
============================================*/
.preloader-style-thirteen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #ffffff;
}
.preloader-style-thirteen .preloader-wrap {
  width: 80px;
  height: 80px;
  position: relative;
  transform: rotate(45deg);
}
.preloader-style-thirteen .preloader-wrap span {
  width: 40px;
  height: 40px;
  background: #FFB037;
  position: absolute;
  -webkit-animation: spin-thirteen 1s linear infinite;
          animation: spin-thirteen 1s linear infinite;
}
.preloader-style-thirteen .preloader-wrap span:nth-child(1) {
  top: 0;
  left: 0;
}
.preloader-style-thirteen .preloader-wrap span:nth-child(2) {
  top: 0;
  right: 0;
}
.preloader-style-thirteen .preloader-wrap span:nth-child(3) {
  bottom: 0;
  left: 0;
}
.preloader-style-thirteen .preloader-wrap span:nth-child(4) {
  bottom: 0;
  right: 0;
}
.preloader-style-thirteen .preloader-wrap .light {
  background: #FFEAA5;
}

@-webkit-keyframes spin-thirteen {
  0% {
    transform: rotate(0);
  }
  50%, 90%, 100% {
    transform: rotate(90deg);
  }
}

@keyframes spin-thirteen {
  0% {
    transform: rotate(0);
  }
  50%, 90%, 100% {
    transform: rotate(90deg);
  }
}
/* Preloader Area Style Thirteen
============================================*/
.preloader-style-fourteen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}
.preloader-style-fourteen .preloader-wrap {
  position: relative;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background: linear-gradient(#00FF00, #00FF00, #00FF00);
  -webkit-animation: rotate-fourteen 1s linear infinite;
          animation: rotate-fourteen 1s linear infinite;
}
.preloader-style-fourteen .preloader-wrap span {
  position: absolute;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background: linear-gradient(#00b2ff, #00ffbb, #6bff6b);
}
.preloader-style-fourteen .preloader-wrap span:nth-child(1) {
  filter: blur(5px);
}
.preloader-style-fourteen .preloader-wrap span:nth-child(2) {
  filter: blur(10px);
}
.preloader-style-fourteen .preloader-wrap::after {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: #162434;
  border-radius: 50%;
}

@-webkit-keyframes rotate-fourteen {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate-fourteen {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Preloader Area Style Thirteen
============================================*/
.preloader-style-fifteen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #ffffff;
}
.preloader-style-fifteen .pl-bow-1 {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  margin: 0 auto 18px auto;
  position: relative;
  width: 50px;
  height: 50px;
}
.preloader-style-fifteen .pl-bow-1:before, .preloader-style-fifteen .pl-bow-1:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background: currentColor;
  display: block;
  transform-origin: 50% 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 50% 100%);
          clip-path: polygon(0 0, 100% 0, 50% 100%);
}
.preloader-style-fifteen .pl-bow-1.pl-bow-2 {
  -webkit-animation-name: rotate-fifteen;
          animation-name: rotate-fifteen;
}
.preloader-style-fifteen .pl-bow-1.pl-bow-2:after {
  transform: rotate(180deg);
}

@-webkit-keyframes rotate-fifteen {
  from {
    transform: scale(1, 1) rotate(0turn);
  }
  25%, 35%, 50% {
    transform: scale(1, 1) rotate(0.75turn);
  }
  30% {
    transform: scale(1.25, 1) rotate(0.75turn);
  }
  75%, 85%, to {
    transform: scale(1, 1) rotate(1.5turn);
  }
  80% {
    transform: scale(1, 1.25) rotate(1.5turn);
  }
}

@keyframes rotate-fifteen {
  from {
    transform: scale(1, 1) rotate(0turn);
  }
  25%, 35%, 50% {
    transform: scale(1, 1) rotate(0.75turn);
  }
  30% {
    transform: scale(1.25, 1) rotate(0.75turn);
  }
  75%, 85%, to {
    transform: scale(1, 1) rotate(1.5turn);
  }
  80% {
    transform: scale(1, 1.25) rotate(1.5turn);
  }
}
/* Preloader Area Style Sixteen
============================================*/
.preloader-style-sixteen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #ffffff;
}
.preloader-style-sixteen .pl-butterfly-1 {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  margin: 0 auto 18px auto;
  position: relative;
  width: 50px;
  height: 50px;
}
.preloader-style-sixteen .pl-butterfly-1:before, .preloader-style-sixteen .pl-butterfly-1:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background: currentColor;
  display: block;
  transform-origin: 50% 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 50% 100%);
          clip-path: polygon(0 0, 100% 0, 50% 100%);
}
.preloader-style-sixteen .pl-butterfly-1.pl-butterfly-2 {
  -webkit-animation-name: butterflyA;
          animation-name: butterflyA;
}
.preloader-style-sixteen .pl-butterfly-1.pl-butterfly-2:before {
  -webkit-animation-name: butterflyB;
          animation-name: butterflyB;
}
.preloader-style-sixteen .pl-butterfly-1.pl-butterfly-2:after {
  -webkit-animation-name: butterflyC;
          animation-name: butterflyC;
}

@-webkit-keyframes butterflyA {
  from, to {
    transform: translateY(0);
  }
  50% {
    transform: translateY(25%);
  }
}

@keyframes butterflyA {
  from, to {
    transform: translateY(0);
  }
  50% {
    transform: translateY(25%);
  }
}
@-webkit-keyframes butterflyB {
  from, 50%, to {
    transform: rotate(60deg);
  }
  25%, 75% {
    transform: rotate(120deg);
  }
}
@keyframes butterflyB {
  from, 50%, to {
    transform: rotate(60deg);
  }
  25%, 75% {
    transform: rotate(120deg);
  }
}
@-webkit-keyframes butterflyC {
  from, 50%, to {
    transform: rotate(300deg);
  }
  25%, 75% {
    transform: rotate(240deg);
  }
}
@keyframes butterflyC {
  from, 50%, to {
    transform: rotate(300deg);
  }
  25%, 75% {
    transform: rotate(240deg);
  }
}
/* Preloader Area Style Sixteen
============================================*/
.preloader-style-seventeen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #ffffff;
}
.preloader-style-seventeen .pl-flip-1 {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  margin: 0 auto 18px auto;
  position: relative;
  width: 50px;
  height: 50px;
}
.preloader-style-seventeen .pl-flip-1:before, .preloader-style-seventeen .pl-flip-1:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  color: #67748e;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background: currentColor;
  display: block;
  transform-origin: 50% 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 50% 100%);
          clip-path: polygon(0 0, 100% 0, 50% 100%);
}
.preloader-style-seventeen .pl-flip-1.pl-flip-2 {
  -webkit-animation-name: flipA;
          animation-name: flipA;
  -webkit-animation-timing-function: steps(1);
          animation-timing-function: steps(1);
}
.preloader-style-seventeen .pl-flip-1.pl-flip-2:before {
  transform-origin: 50% 50%;
  -webkit-animation-name: flipB;
          animation-name: flipB;
}
.preloader-style-seventeen .pl-flip-1.pl-flip-2:after {
  transform-origin: 50% 50%;
  -webkit-animation-name: flipC;
          animation-name: flipC;
}

@-webkit-keyframes flipA {
  from, 75%, to {
    transform: rotate(0);
  }
  25%, 50% {
    transform: rotate(90deg);
  }
}

@keyframes flipA {
  from, 75%, to {
    transform: rotate(0);
  }
  25%, 50% {
    transform: rotate(90deg);
  }
}
@-webkit-keyframes flipB {
  from {
    transform: translateY(0) rotateX(0deg);
  }
  6.25% {
    transform: translateY(0) rotateX(0deg);
  }
  12.5% {
    transform: translateY(-50%) rotateX(90deg);
  }
  18.75% {
    transform: translateY(0) rotateX(180deg);
  }
  31.25% {
    transform: translateY(0) rotateX(180deg);
  }
  37.5% {
    transform: translateY(-50%) rotateX(270deg);
  }
  43.75% {
    transform: translateY(0) rotateX(360deg);
  }
  50% {
    transform: translateY(0) rotateX(360deg);
  }
  56.25% {
    transform: translateY(0) rotateX(360deg);
  }
  62.5% {
    transform: translateY(-50%) rotateX(450deg);
  }
  68.75% {
    transform: translateY(0) rotateX(540deg);
  }
  81.25% {
    transform: translateY(0) rotateX(540deg);
  }
  87.5% {
    transform: translateY(-50%) rotateX(630deg);
  }
  93.75% {
    transform: translateY(0) rotateX(720deg);
  }
  to {
    transform: translateY(0) rotateX(720deg);
  }
}
@keyframes flipB {
  from {
    transform: translateY(0) rotateX(0deg);
  }
  6.25% {
    transform: translateY(0) rotateX(0deg);
  }
  12.5% {
    transform: translateY(-50%) rotateX(90deg);
  }
  18.75% {
    transform: translateY(0) rotateX(180deg);
  }
  31.25% {
    transform: translateY(0) rotateX(180deg);
  }
  37.5% {
    transform: translateY(-50%) rotateX(270deg);
  }
  43.75% {
    transform: translateY(0) rotateX(360deg);
  }
  50% {
    transform: translateY(0) rotateX(360deg);
  }
  56.25% {
    transform: translateY(0) rotateX(360deg);
  }
  62.5% {
    transform: translateY(-50%) rotateX(450deg);
  }
  68.75% {
    transform: translateY(0) rotateX(540deg);
  }
  81.25% {
    transform: translateY(0) rotateX(540deg);
  }
  87.5% {
    transform: translateY(-50%) rotateX(630deg);
  }
  93.75% {
    transform: translateY(0) rotateX(720deg);
  }
  to {
    transform: translateY(0) rotateX(720deg);
  }
}
@-webkit-keyframes flipC {
  from {
    transform: translateY(100%) rotateZ(180deg) rotateX(0deg);
  }
  6.25% {
    transform: translateY(100%) rotateZ(180deg) rotateX(0deg);
  }
  12.5% {
    transform: translateY(150%) rotateZ(180deg) rotateX(90deg);
  }
  18.75% {
    transform: translateY(100%) rotateZ(180deg) rotateX(180deg);
  }
  31.25% {
    transform: translateY(100%) rotateZ(180deg) rotateX(180deg);
  }
  37.5% {
    transform: translateY(150%) rotateZ(180deg) rotateX(270deg);
  }
  43.75% {
    transform: translateY(100%) rotateZ(180deg) rotateX(360deg);
  }
  50% {
    transform: translateY(100%) rotateZ(180deg) rotateX(360deg);
  }
  56.25% {
    transform: translateY(100%) rotateZ(180deg) rotateX(360deg);
  }
  62.5% {
    transform: translateY(150%) rotateZ(180deg) rotateX(450deg);
  }
  68.75% {
    transform: translateY(100%) rotateZ(180deg) rotateX(540deg);
  }
  81.25% {
    transform: translateY(100%) rotateZ(180deg) rotateX(540deg);
  }
  87.5% {
    transform: translateY(150%) rotateZ(180deg) rotateX(630deg);
  }
  93.75% {
    transform: translateY(100%) rotateZ(180deg) rotateX(720deg);
  }
  to {
    transform: translateY(100%) rotateZ(180deg) rotateX(720deg);
  }
}
@keyframes flipC {
  from {
    transform: translateY(100%) rotateZ(180deg) rotateX(0deg);
  }
  6.25% {
    transform: translateY(100%) rotateZ(180deg) rotateX(0deg);
  }
  12.5% {
    transform: translateY(150%) rotateZ(180deg) rotateX(90deg);
  }
  18.75% {
    transform: translateY(100%) rotateZ(180deg) rotateX(180deg);
  }
  31.25% {
    transform: translateY(100%) rotateZ(180deg) rotateX(180deg);
  }
  37.5% {
    transform: translateY(150%) rotateZ(180deg) rotateX(270deg);
  }
  43.75% {
    transform: translateY(100%) rotateZ(180deg) rotateX(360deg);
  }
  50% {
    transform: translateY(100%) rotateZ(180deg) rotateX(360deg);
  }
  56.25% {
    transform: translateY(100%) rotateZ(180deg) rotateX(360deg);
  }
  62.5% {
    transform: translateY(150%) rotateZ(180deg) rotateX(450deg);
  }
  68.75% {
    transform: translateY(100%) rotateZ(180deg) rotateX(540deg);
  }
  81.25% {
    transform: translateY(100%) rotateZ(180deg) rotateX(540deg);
  }
  87.5% {
    transform: translateY(150%) rotateZ(180deg) rotateX(630deg);
  }
  93.75% {
    transform: translateY(100%) rotateZ(180deg) rotateX(720deg);
  }
  to {
    transform: translateY(100%) rotateZ(180deg) rotateX(720deg);
  }
}
/* Preloader Area Style Eighteen
============================================*/
.preloader-style-eighteen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #ffffff;
}
.preloader-style-eighteen .pl-kinetic-1 {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  margin: 0 auto 18px auto;
  position: relative;
  width: 50px;
  height: 50px;
}
.preloader-style-eighteen .pl-kinetic-1:before, .preloader-style-eighteen .pl-kinetic-1:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background: currentColor;
  display: block;
  transform-origin: 50% 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 50% 100%);
          clip-path: polygon(0 0, 100% 0, 50% 100%);
}
.preloader-style-eighteen .pl-kinetic-1.pl-kinetic-2:before {
  -webkit-animation-name: kineticA;
          animation-name: kineticA;
}
.preloader-style-eighteen .pl-kinetic-1.pl-kinetic-2:after {
  -webkit-animation-name: kineticB;
          animation-name: kineticB;
}

@-webkit-keyframes kineticA {
  from, 25% {
    transform: rotate(0deg);
  }
  50%, 75% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes kineticA {
  from, 25% {
    transform: rotate(0deg);
  }
  50%, 75% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes kineticB {
  from {
    transform: rotate(90deg);
  }
  25%, 50% {
    transform: rotate(270deg);
  }
  75%, to {
    transform: rotate(450deg);
  }
}
@keyframes kineticB {
  from {
    transform: rotate(90deg);
  }
  25%, 50% {
    transform: rotate(270deg);
  }
  75%, to {
    transform: rotate(450deg);
  }
}
/* Preloader Area Style Nineteen
============================================*/
.preloader-style-nineteen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #ffffff;
}
.preloader-style-nineteen .pl-spark-1 {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  margin: 0 auto 18px auto;
  position: relative;
  width: 50px;
  height: 50px;
}
.preloader-style-nineteen .pl-spark-1:before, .preloader-style-nineteen .pl-spark-1:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background: currentColor;
  display: block;
  transform-origin: 50% 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 50% 100%);
          clip-path: polygon(0 0, 100% 0, 50% 100%);
}
.preloader-style-nineteen .pl-spark-1.pl-spark-2:before {
  -webkit-animation-name: scaleA;
          animation-name: scaleA;
}
.preloader-style-nineteen .pl-spark-1.pl-spark-2:after {
  -webkit-animation-name: scaleB;
          animation-name: scaleB;
}

@-webkit-keyframes scaleA {
  from, 50%, to {
    transform: scale(1);
  }
  25%, 75% {
    transform: scale(-1);
  }
}

@keyframes scaleA {
  from, 50%, to {
    transform: scale(1);
  }
  25%, 75% {
    transform: scale(-1);
  }
}
@-webkit-keyframes scaleB {
  from, 50%, to {
    transform: rotate(90deg) scale(0);
  }
  12.5%, 62.5% {
    transform: rotate(90deg) scale(1);
  }
  37.5%, 87.5% {
    transform: rotate(90deg) scale(-1);
  }
}
@keyframes scaleB {
  from, 50%, to {
    transform: rotate(90deg) scale(0);
  }
  12.5%, 62.5% {
    transform: rotate(90deg) scale(1);
  }
  37.5%, 87.5% {
    transform: rotate(90deg) scale(-1);
  }
}
/* Preloader Area Style Twenty
============================================*/
.preloader-style-twenty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #ffffff;
}
.preloader-style-twenty .pl-star-1 {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  margin: 0 auto 18px auto;
  position: relative;
  width: 50px;
  height: 50px;
}
.preloader-style-twenty .pl-star-1:before, .preloader-style-twenty .pl-star-1:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  background: currentColor;
  display: block;
  transform-origin: 50% 100%;
  -webkit-clip-path: polygon(0 0, 100% 0, 50% 100%);
          clip-path: polygon(0 0, 100% 0, 50% 100%);
}
.preloader-style-twenty .pl-star-1.pl-star-2 {
  -webkit-animation-name: starA;
          animation-name: starA;
}
.preloader-style-twenty .pl-star-1.pl-star-2:after {
  transform: rotate(180deg);
}

@-webkit-keyframes starA {
  from, to {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
  25%, 75.1% {
    opacity: 0;
    transform: rotate(0deg) scale(0);
  }
  25.1%, 75% {
    opacity: 0;
    transform: rotate(90deg) scale(0);
  }
  50% {
    opacity: 1;
    transform: rotate(90deg) scale(1);
  }
}

@keyframes starA {
  from, to {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
  25%, 75.1% {
    opacity: 0;
    transform: rotate(0deg) scale(0);
  }
  25.1%, 75% {
    opacity: 0;
    transform: rotate(90deg) scale(0);
  }
  50% {
    opacity: 1;
    transform: rotate(90deg) scale(1);
  }
}
/* Preloader Loading Content
============================================*/
.preloader-loading-content {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 1140px;
  transform: translate(-50%, -50%);
  text-align: center;
}
.preloader-loading-content h2 {
  font-size: 50px;
  margin-bottom: 20px;
}
.preloader-loading-content p {
  line-height: 1.5;
}

/* Preloader Close Btn
============================================*/
.preloader-close-btn {
  background-color: transparent;
  position: relative;
  background-color: #ef1144;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: none;
  cursor: pointer;
  float: right;
  z-index: 99999;
  transition: all ease 0.5s;
}
.preloader-close-btn:hover {
  background-color: #ff0000;
}
.preloader-close-btn::before {
  content: "";
  position: absolute;
  top: 13px;
  left: 8px;
  width: 15px;
  height: 2px;
  background-color: #ffffff;
  transform: rotate(-45deg);
}
.preloader-close-btn::after {
  content: "";
  position: absolute;
  top: 13px;
  left: 8px;
  width: 15px;
  height: 2px;
  background-color: #ffffff;
  transform: rotate(45deg);
}
.preloader-close-btn:focus {
  box-shadow: none;
  border: none;
  outline: none;
}