.tss-1cdcmys-MUIDataTable-responsiveBase {
    position: inherit !important;
}


/* AnimatedBox.css */
.animated-box {
    /* Add your styles for the container here */
    transition: opacity 0.5s ease-in-out;
    /* Add the transition property */
}

.animated-box.show {
    /* Add your styles for the visible container here */
    opacity: 1;
    /* Make the container fully visible when 'show' class is added */
}

