/*
Header Content Content Style
=====================================================*/
.header-area {
    position: fixed;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 9;
}

.header-area.is-sticky {
    /* -webkit-animation: 0.5s ease-in-out 0s normal none 1 running fadeInDown;
    animation: 0.5s ease-in-out 0s normal none 1 running fadeInDown; */
    /* top: 12px; */
    border-radius: 0 0 30px 30px;
}


.header-area.is-sticky::before {
    content: "";
    position: absolute;
    top: -15px;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #041535;
    z-index: -1;
}

.header-area.is-sticky .header-content-wrapper {
    /* border-radius: 0 0 30px 30px; */
    /* -webkit-animation: 0.5s ease-in-out 0s normal none 1 running fadeInDown;
    animation: 0.5s ease-in-out 0s normal none 1 running fadeInDown; */
    margin-bottom: 0;
    /* margin-top: 0; */
}

.header-area .responsive-burger-menu {
    cursor: pointer;
    transition: all ease 0.5s;
    margin-right: 20px;
}

.header-area .responsive-burger-menu span {
    height: 1px;
    width: 25px;
    background: #292d32;
    display: block;
    margin: 6px 0;
    transition: all 0.5s ease-in-out;
}

.header-area .responsive-burger-menu.active span.top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
}

.header-area .responsive-burger-menu.active span.middle-bar {
    opacity: 0;
}

.header-area .responsive-burger-menu.active span.bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
    margin-top: 5px;
}

.header-area.header-style-two {
    background-color: #F8FAFF;
}

.header-area.header-style-two .header-content-wrapper .header-left-content .main-logo {
    margin-right: 175px;
}

.header-area.header-style-three .header-content-wrapper {
    background: transparent linear-gradient(93deg, #1765FD 0%, #4FCB8D 100%) 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.0196078431);
    padding: 0px 20px; */
}

.header-area.header-style-three .header-content-wrapperr {
    background: transparent linear-gradient(93deg, #1765FD 0%, #4FCB8D 100%) 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.0196078431);
    padding: 0px 20px; */
}

.header-area.header-style-three .header-content-wrapper .header-left-content .main-logo {
    margin-right: 135px;
}

.header-area.header-style-three .header-content-wrapper .header-left-content .search-bar .form-control {
    background: rgba(248, 250, 255, 0.16) 0% 0% no-repeat padding-box !important;
    border: 1px solid rgba(248, 250, 255, 0.16);
    color: #ffffff;
}

.header-area.header-style-three .header-content-wrapper .header-left-content .search-bar .form-control::-moz-placeholder {
    color: #ffffff;
}

.header-area.header-style-three .header-content-wrapper .header-left-content .search-bar .form-control:-ms-input-placeholder {
    color: #ffffff;
}

.header-area.header-style-three .header-content-wrapper .header-left-content .search-bar .form-control::placeholder {
    color: #ffffff;
}

.header-area.header-style-three .header-content-wrapper .header-left-content .search-bar img {
    filter: brightness(100);
}

.header-area.header-style-three .header-content-wrapper .header-right-content .header-right-option .dropdown-item img {
    filter: brightness(100);
}

.header-area.header-style-three .header-content-wrapper .header-right-content .header-right-option .dropdown-item.avatar h3 {
    color: #ffffff;
}

.header-area.header-style-three .header-content-wrapper .header-right-content .header-right-option .dropdown-item.avatar span {
    color: #E5FFF2;
}

.header-area.header-style-three .header-content-wrapper .header-right-content .header-right-option .dropdown-item.avatar img {
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    filter: none;
    width: 48px;
    border-radius: 18px;
}

.header-area.header-style-three .header-content-wrapper .header-right-content .header-right-option.template-option img {
    filter: brightness(0);
}

.header-content-wrapper {
    background-color: #ffffff !important;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 0px 20px;
    margin-top: 12px;
}

.header-content-wrapperr {
    background-color: #ffffff !important;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    border-radius: 15px;
    padding: 21px 20px;
    margin-top: 12px;
    margin-bottom: 30px;
}

.header-left-content .main-logo {
    margin-right: 50px;
}

.header-left-content .search-bar {
    position: relative;
}

.header-left-content .search-bar .form-control {
    border-radius: 10px;
    background-color: #f5f5f5;
    width: 460px;
    padding-left: 50px;
    height: 48px;
    background: #F8FAFF 0% 0% no-repeat padding-box;
    border: 1px solid #CAD6F2;
    border-radius: 50px;
    font-size: 14px;
}

.header-left-content .search-bar .form-control::-moz-placeholder {
    color: #67748e;
}

.header-left-content .search-bar .form-control:-ms-input-placeholder {
    color: #67748e;
}

.header-left-content .search-bar .form-control::placeholder {
    color: #67748e;
}

.header-left-content .search-bar .form-control:focus {
    border-color: #4fcb8d;
}

.header-left-content .search-bar img {
    position: absolute;
    top: 14px;
    left: 20px;
}

.header-left-content .search-bar img svg {
    fill: #292D32;
    width: 20px;
    height: 20px;
    line-height: 20px;
}

.header-left-content .for-mobile-devices {
    display: none !important;
}

.header-left-content .for-mobile-devices.option-item {
    color: #67748e;
    display: inline-block;
    line-height: 1;
    position: relative;
    top: 0;
}

.header-left-content .for-mobile-devices.option-item span {
    position: relative;
    top: -7px;
    margin-left: 5px;
    font-weight: 600;
}

.header-left-content .for-mobile-devices.option-item .search-overlay {
    display: none;
}

.header-left-content .for-mobile-devices.option-item .search-overlay.search-popup {
    position: absolute;
    top: 100%;
    width: 300px;
    right: -216px;
    background: #ffffff;
    z-index: 2;
    padding: 20px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    margin-top: 18px;
}

.header-left-content .for-mobile-devices.option-item .search-overlay.search-popup .search-form {
    position: relative;
}

.header-left-content .for-mobile-devices.option-item .search-overlay.search-popup .search-form .search-input {
    display: block;
    width: 100%;
    height: 50px;
    line-height: initial;
    border: 1px solid #eeeeee;
    color: #292d32;
    outline: 0;
    transition: all ease 0.5s;
    font-size: 15px;
    padding-top: 4px;
    padding-left: 15px;
}

.header-left-content .for-mobile-devices.option-item .search-overlay.search-popup .search-form .search-input:focus {
    border-color: #4fcb8d;
}

.header-left-content .for-mobile-devices.option-item .search-overlay.search-popup .search-form .search-button {
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    background: #4fcb8d;
    border: none;
    width: 50px;
    outline: 0;
    color: #ffffff;
    transition: all ease 0.5s;
    padding: 0;
}

.header-left-content .for-mobile-devices.option-item .search-overlay.search-popup .search-form .search-button:hover {
    background-color: #292d32;
}

.header-left-content .for-mobile-devices.option-item .search-btn {
    cursor: pointer;
    transition: all ease 0.5s;
    color: #292d32;
    text-align: center;
    font-size: 21px;
    display: inline-block;
}

.header-left-content .for-mobile-devices.option-item .search-btn:hover {
    color: #4fcb8d;
}

.header-left-content .for-mobile-devices.option-item .close-btn {
    cursor: pointer;
    transition: all ease 0.5s;
    color: #292d32;
    text-align: center;
    display: none;
    font-size: 21px;
}

.header-left-content .for-mobile-devices.option-item .close-btn:hover {
    color: #4fcb8d;
}

.header-left-content .for-mobile-devices.option-item .close-btn.active {
    display: block;
    color: #4fcb8d;
}

.header-right-content {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
}

.header-right-content .header-right-option {
    margin-left: 30px;
}

.header-right-content .header-right-option .dropdown-item {
    position: relative;
}

.header-right-content .header-right-option .dropdown-item::after {
    display: none;
}

.header-right-content .header-right-option .dropdown-item.fullscreen-btn {
    cursor: pointer;
}

.header-right-content .header-right-option .dropdown-item.fullscreen-btn i {
    transition: all ease 0.5s;
}

.header-right-content .header-right-option .dropdown-item.fullscreen-btn:hover i {
    color: #4fcb8d;
}

.header-right-content .header-right-option .dropdown-item.fullscreen-btn.active {
    position: relative;
}

.header-right-content .header-right-option .dropdown-item.fullscreen-btn.active::before {
    content: "";
    position: absolute;
    top: -12px;
    left: -16px;
    width: 24px;
    height: 24px;
    /* background-image: url(../images/full-screen.png); */
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.header-right-content .header-right-option .dropdown-item.fullscreen-btn.active img {
    display: none;
}

.header-right-content .header-right-option .dropdown-item.avatar h3 {
    font-size: 16px;
    margin-bottom: 0;
    font-weight: 600;
}

.header-right-content .header-right-option .dropdown-item.avatar span {
    font-size: 12px;
}

.header-right-content .header-right-option .dropdown-item.avatar img {
    margin-right: 15px;
    width: 48px;
    border-radius: 18px;
}

.header-right-content .header-right-option .dropdown-item .badge {
    position: absolute;
    top: -7px;
    right: -5px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    line-height: 15px;
    font-size: 10px;
    padding: 0;
    text-align: center;
    background-color: #4fcb8d;
}

.header-right-content .header-right-option.apps-option .dropdown-menu {
    width: 350px;
    max-width: 350px;
    border-radius: 10px 10px 0 0;
    padding: 0;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    top: 70px !important;
    transition: all ease 0.5s !important;
}

.header-right-content .header-right-option.apps-option .dropdown-menu.show {
    top: 40px !important;
}

.header-right-content .header-right-option.apps-option .dropdown-menu .dropdown-header {
    border-radius: 10px 10px 0 0;
    color: #ffffff;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 9px;
    padding-bottom: 7px;
}

.header-right-content .header-right-option.apps-option .dropdown-menu .dropdown-header span {
    font-size: 13px;
    font-weight: 500;
}

.header-right-content .header-right-option.apps-option .dropdown-menu .dropdown-header span.edit-btn {
    cursor: pointer;
}

.header-right-content .header-right-option.apps-option .dropdown-menu .dropdown-wrap {
    padding: 10px 5px;
    height: 170px;
}

.header-right-content .header-right-option.apps-option .dropdown-menu .dropdown-wrap .dropdown-item {
    flex: 0 0 25%;
    max-width: 25%;
    text-align: center;
    padding: 8px 10px;
    border-radius: 10px;
}

.header-right-content .header-right-option.apps-option .dropdown-menu .dropdown-wrap .dropdown-item img {
    filter: none;
}

.header-right-content .header-right-option.apps-option .dropdown-menu .dropdown-wrap .dropdown-item span {
    margin-top: 8px;
    font-size: 13px;
    color: #292d32;
    font-weight: 500;
}

.header-right-content .header-right-option.apps-option .dropdown-menu .dropdown-footer {
    text-align: center;
    padding: 10px 15px;
    border-top: 1px solid #eeeeee;
}

.header-right-content .header-right-option.apps-option .dropdown-menu .dropdown-footer .dropdown-item {
    color: #2962ff !important;
    padding: 0;
    position: relative;
    display: inline-block;
    width: auto;
    background-color: transparent !important;
    font-size: 13px;
    font-weight: 500;
}

.header-right-content .header-right-option.messenger-option .dropdown-menu {
    transition: all ease 0.5s;
    top: 60px !important;
}

.header-right-content .header-right-option.messenger-option .dropdown-menu.show {
    transition: all ease 0.5s !important;
    top: 45px !important;
}

.header-right-content .header-right-option.messenger-option .dropdown-menu .dropdown-wrap .dropdown-item .icon {
    background-color: transparent;
}

.header-right-content .header-right-option.messenger-option .dropdown-menu .dropdown-wrap .dropdown-item img {
    filter: none;
}

.header-right-content .header-right-option.messenger-option .badge {
    background-color: #82D973;
}

.header-right-content .header-right-option.notification-option .notification-btn .badge {
    background-color: red;
}

.header-right-content .header-right-option.notification-option .dropdown-item {
    background-color: transparent;
}

.header-right-content .header-right-option.notification-option .dropdown-menu {
    width: 350px;
    max-width: 350px;
    padding: 0;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    transition: all ease 0.5s;
    top: 60px !important;
}

.header-right-content .header-right-option.notification-option .dropdown-menu.show {
    transition: all ease 0.5s !important;
    top: 40px !important;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-header {
    border-radius: 10px 10px 0 0;
    background-color: #4fcb8d;
    color: #ffffff;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 9px;
    padding-bottom: 7px;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-header span {
    font-size: 13px;
    font-weight: 500;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-header span.mark-all-btn {
    cursor: pointer;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap {
    height: 200px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item {
    padding: 7px 15px;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;
    background: #f7f7f7;
    text-align: center;
    color: #4fcb8d;
    transition: all ease 0.5s;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item .icon i {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item .content {
    padding-left: 15px;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item .content span {
    color: #292d32;
    margin-bottom: 1px;
    font-size: 13px;
    font-weight: 500;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item .content p {
    font-size: 12px;
    color: #686868;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item:hover .icon {
    background-color: #4fcb8d;
    color: #ffffff;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item:nth-child(2) .icon {
    background: rgba(114, 124, 245, 0.1);
    color: #727cf5;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item:nth-child(2):hover .icon {
    background-color: #727cf5;
    color: #ffffff;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item:nth-child(3) .icon {
    background: rgba(16, 183, 89, 0.1);
    color: #10b759;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item:nth-child(3):hover .icon {
    background-color: #10b759;
    color: #ffffff;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item:nth-child(4) .icon {
    background: rgba(255, 51, 102, 0.1);
    color: #f36;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item:nth-child(4):hover .icon {
    background-color: #f36;
    color: #ffffff;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item:nth-child(5) .icon {
    background: rgba(251, 188, 6, 0.1);
    color: #fbbc06;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-wrap .dropdown-item:nth-child(5):hover .icon {
    background-color: #fbbc06;
    color: #ffffff;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-footer {
    text-align: center;
    padding: 10px 15px;
    border-top: 1px solid #eeeeee;
}

.header-right-content .header-right-option.notification-option .dropdown-menu .dropdown-footer .dropdown-item {
    color: #4fcb8d !important;
    padding: 0;
    position: relative;
    display: inline-block;
    width: auto;
    background-color: transparent !important;
    font-size: 13px;
    font-weight: 500;
}

.header-right-content .header-right-option.notification-option.messenger-option .dropdown-menu .dropdown-wrap .dropdown-item .icon {
    display: contents;
}

.header-right-content .header-right-option.notification-option.messenger-option .dropdown-menu .dropdown-wrap .dropdown-item .icon img {
    border-radius: 8px;
    width: 30px;
    height: 30px;
}

.header-right-content .header-right-option.profile-nav-item {
    margin-left: 20px;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu {
    padding: 15px 15px 10px;
    min-width: 230px;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    border-radius: 10px;
    transition: all ease 0.5s;
    top: 60px !important;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu.show {
    transition: all ease 0.5s !important;
    top: 29px !important;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-header {
    padding: 0;
    border-bottom: 1px solid #f2f4f9;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-header .figure {
    position: relative;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-header .figure img {
    width: 48px;
    border-radius: 50px;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-header .name {
    display: block;
    color: #292d32;
    margin-bottom: 2px;
    font-size: 16px;
    font-weight: 600;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-header .email {
    color: #686868;
    font-size: 12px;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-wrap .profile-nav {
    padding: 0;
    list-style-type: none;
    margin-bottom: 0;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-wrap .profile-nav .nav-item {
    margin-left: 0;
    margin-bottom: 5px;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-wrap .profile-nav .nav-item:last-child {
    margin-bottom: 0;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-wrap .profile-nav .nav-item .nav-link {
    position: relative;
    padding-left: 40px;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-wrap .profile-nav .nav-item .nav-link i {
    color: #292d32;
    transition: all ease 0.5s;
    position: absolute;
    left: 15px;
    top: 1px;
    transition: all ease 0.5s;
    font-size: 16px;
    font-weight: normal;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-wrap .profile-nav .nav-item .nav-link span {
    font-size: 13px !important;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-wrap .profile-nav .nav-item .nav-link:hover {
    color: #4fcb8d;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-wrap .profile-nav .nav-item .nav-link:hover i {
    color: #4fcb8d;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-wrap .profile-nav .nav-item .dropdown-item {
    color: #292d32;
    padding: 5px 15px 5px 38px;
    position: relative;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-wrap .profile-nav .nav-item .dropdown-item.active,
.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-wrap .profile-nav .nav-item .dropdown-item:active {
    color: #292d32;
    background-color: transparent;
    background-color: #f8f9fa;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-wrap .profile-nav .nav-item .dropdown-item:hover {
    color: #4fcb8d;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-wrap .profile-nav .nav-item .dropdown-item:hover i {
    color: #4fcb8d;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-footer {
    margin: 10px -15px 0;
    padding: 10px 15px 0;
    border-top: 1px solid #eeeeee;
    background-color: transparent;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-footer .profile-nav {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-footer .profile-nav .nav-item {
    margin-left: 0;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-footer .nav-item .nav-link {
    color: #4fcb8d;
    padding: 5px 15px 5px 38px;
    position: relative;
    font-size: 13px;
    font-weight: 500;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-footer .nav-item .nav-link i {
    color: #4fcb8d;
    transition: all ease 0.5s;
    position: absolute;
    left: 15px;
    top: 2.1px;
    font-size: 16px;
}

.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-footer .nav-item .nav-link.active,
.header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu .dropdown-footer .nav-item .nav-link:active {
    color: #292d32;
    background-color: transparent;
    background-color: #f8f9fa;
}

.header-right-content .header-right-option.template-option a {
    width: 40px;
    height: 40px;
    line-height: 38px;
    text-align: center;
    box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    border-radius: 16px;
}

.header-right-content .header-right-option:first-child {
    margin-left: 0;
}

.header-right-content .dropdown-item {
    white-space: unset !important;
}

.simplebar-content-wrapper {
    visibility: unset;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: transparent;
}

/* Max width 767px */
@media only screen and (max-width: 767px) {
    .header-area .responsive-burger-menu {
        margin-right: 10px;
    }

    .header-content-wrapper {
        padding: 20px 15px;
        border-radius: 10px;
    }

    .header-left-content .for-mobile-devices {
        display: block !important;
        margin-right: 0;
    }

    .header-left-content .for-mobile-devices.option-item {
        margin-right: 0;
        top: 3px;
    }

    .header-left-content .for-mobile-devices.option-item .search-overlay.search-popup {
        right: -235px;
        border-radius: 30px;
    }

    .header-left-content .for-mobile-devices.option-item .search-overlay.search-popup .search-form .search-input {
        border-radius: 30px;
    }

    .header-left-content .for-mobile-devices.option-item .search-overlay.search-popup .search-form .search-button {
        border-radius: 0 30px 30px 0;
    }

    .header-left-content .search-bar {
        display: none !important;
    }

    .header-left-content .main-logo {
        display: none;
    }

    .header-left-content .search-bar .form-control {
        width: 100%;
    }

    .header-right-content {
        margin-left: 10px;
    }

    .header-right-content .header-right-option {
        margin-right: 0;
        margin-left: 10px;
    }

    .header-right-content .header-right-option .dropdown-menu {
        width: 300px !important;
    }

    .header-right-content .header-right-option .dropdown-item img {
        width: 20px;
    }

    .header-right-content .header-right-option.notification-option .dropdown-menu {
        transform: unset !important;
        left: auto !important;
        right: -97px !important;
    }

    .header-right-content .header-right-option.notification-option .dropdown-menu.show {
        top: 40px !important;
    }

    .header-right-content .header-right-option.messenger-option .dropdown-menu {
        transform: unset !important;
        left: auto !important;
        right: -127px !important;
    }

    .header-right-content .header-right-option.messenger-option .dropdown-menu.show {
        top: 40px !important;
    }

    .header-right-content .header-right-option.apps-option .dropdown-menu {
        transform: unset !important;
        left: auto !important;
        right: -157px !important;
    }

    .header-right-content .header-right-option.apps-option .dropdown-menu.show {
        top: 40px !important;
    }

    .header-right-content .header-right-option .iconsax svg {
        width: 20px;
        height: 20px;
    }

    .header-right-content .header-right-option .template-option a {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    .header-right-content .header-right-option .dropdown-item.avatar img {
        max-width: 30px;
        margin-right: 0;
    }

    .header-right-content .header-right-option.template-option a {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    .header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu.show {
        top: 19px !important;
    }

    .header-right-content .header-right-option.apps-option .dropdown-menu .dropdown-wrap .dropdown-item span {
        font-size: 11px;
    }

    .header-right-content .header-right-option.apps-option .dropdown-menu {
        right: -167px !important;
    }

    .header-right-content .header-right-option.notification-option .dropdown-menu {
        right: -107px !important;
    }

    .header-right-content .header-right-option.messenger-option .dropdown-menu {
        right: -138px !important;
    }
}

/* Min width 576px to Max width 767px */
@media only screen and (min-width: 576px) and (max-width: 767px) {
    .header-content-wrapper {
        padding: 20px;
    }

    .header-right-content .header-right-option .dropdown-item img {
        width: auto;
    }
}

/* Min width 768px to Max width 991px */
@media only screen and (min-width: 768px) and (max-width: 991px) {

    /* Header Left Content */
    .header-left-content {
        align-items: center !important;
    }

    .header-left-content .for-mobile-devices {
        display: block !important;
        margin-right: 0;
    }

    .header-left-content .for-mobile-devices.option-item {
        margin-right: 0;
        top: 3px;
    }

    .header-left-content .for-mobile-devices.option-item .search-overlay.search-popup {
        border-radius: 30px;
    }

    .header-left-content .for-mobile-devices.option-item .search-overlay.search-popup .search-form .search-input {
        border-radius: 30px;
    }

    .header-left-content .for-mobile-devices.option-item .search-overlay.search-popup .search-form .search-button {
        border-radius: 0 30px 30px 0;
    }

    .header-left-content .search-bar {
        display: none !important;
    }

    .header-left-content .main-logo {
        margin-right: 20px;
    }

    .header-left-content .search-bar .form-control {
        width: 100%;
    }

    /* Header Right Content */
    .header-right-content .header-right-option.notification-option .dropdown-menu.show {
        top: 30px !important;
    }

    .header-right-content .header-right-option.messenger-option .dropdown-menu.show {
        top: 30px !important;
    }

    .header-right-content .header-right-option.apps-option .dropdown-menu.show {
        top: 30px !important;
    }

    .header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu.show {
        top: 18px !important;
    }
}

/* Min width 992px to Max width 1199px */
@media only screen and (min-width: 992px) and (max-width: 1199px) {

    /* Header Left Content */
    .header-left-content {
        align-items: center !important;
    }

    .header-left-content .for-mobile-devices {
        display: block !important;
        margin-right: 0;
    }

    .header-left-content .for-mobile-devices.option-item {
        margin-right: 0;
        top: 3px;
    }

    .header-left-content .for-mobile-devices.option-item .search-overlay.search-popup {
        border-radius: 30px;
    }

    .header-left-content .for-mobile-devices.option-item .search-overlay.search-popup .search-form .search-input {
        border-radius: 30px;
    }

    .header-left-content .for-mobile-devices.option-item .search-overlay.search-popup .search-form .search-button {
        border-radius: 0 30px 30px 0;
    }

    .header-left-content .search-bar {
        display: none !important;
    }

    .header-left-content .main-logo {
        margin-right: 20px;
    }

    .header-left-content .search-bar .form-control {
        width: 100%;
    }

    /* Header Right Content */
    .header-right-content .header-right-option.notification-option .dropdown-menu.show {
        top: 30px !important;
    }

    .header-right-content .header-right-option.messenger-option .dropdown-menu.show {
        top: 30px !important;
    }

    .header-right-content .header-right-option.apps-option .dropdown-menu.show {
        top: 30px !important;
    }

    .header-right-content .header-right-option.profile-nav-item.dropdown .dropdown-menu.show {
        top: 18px !important;
    }

    .responsive-burger-menu {
        display: block !important;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .header-area.header-style-two {
        background-color: transparent;
    }

    .header-area.header-style-two .header-content-wrapper .header-left-content .main-logo {
        margin-right: 50px;
    }

    .header-area.header-style-three .header-content-wrapper .header-left-content .main-logo {
        margin-right: 50px;
    }
}

/* Max width 1199px */
@media only screen and (max-width: 1199px) {
    .header-area.header-style-three .responsive-burger-menu span {
        background-color: #ffffff;
    }

    .header-area.header-style-three .header-left-content .for-mobile-devices.option-item .search-btn {
        color: #ffffff;
    }

    .header-area.header-style-three .header-left-content .for-mobile-devices.option-item .search-btn.active {
        color: #ffffff;
    }

    .header-area.header-style-three .header-left-content .for-mobile-devices.option-item .close-btn {
        color: #ffffff;
    }

    .header-area.header-style-three .header-left-content .for-mobile-devices.option-item .close-btn.active {
        color: #ffffff;
    }

    .header-area.header-style-three .responsive-burger-menu span {
        background-color: #ffffff;
    }

    .header-area.header-style-two {
        background-color: transparent;
    }

    .header-area.is-sticky::before {
        height: 100px;
    }

    .header-area.header-style-two .header-content-wrapper .header-left-content .main-logo {
        margin-right: 30px;
    }

    .header-area.header-style-three .header-content-wrapper .header-left-content .main-logo {
        margin-right: 30px;
    }
}

.header-area.examView.is-sticky::before {
    background-color: #fff !important;
}