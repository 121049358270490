.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.loader-div{
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}


      a {
        text-decoration: none !important;
      }
      .dataTables_length{
        text-align: right !important;
      }
      .dataTables_paginate{
        text-align: right !important;
        margin-top: -3%;
        margin-bottom: 1rem !important;
      }
      .dataTables_filter{
        margin-top: -1.5rem !important;

      }
      .dataTables_length label select{
        --bs-btn-padding-x: 0.75rem;
        --bs-btn-padding-y: 0.375rem;
        --bs-btn-font-family: ;
        --bs-btn-font-size: 1rem;
        --bs-btn-font-weight: 400;
        --bs-btn-line-height: 1.5;
        --bs-btn-color: var(--bs-body-color);
        --bs-btn-bg: transparent;
        --bs-btn-border-width: var(--bs-border-width);
        --bs-btn-border-color: transparent;
        --bs-btn-border-radius: var(--bs-border-radius);
        --bs-btn-hover-border-color: transparent;
        --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),0 1px 1px rgba(0, 0, 0, 0.075);
        --bs-btn-disabled-opacity: 0.65;
        --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
        display: inline-block;
        padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
        font-family: var(--bs-btn-font-family);
        font-size: var(--bs-btn-font-size);
        font-weight: var(--bs-btn-font-weight);
        line-height: var(--bs-btn-line-height);
        color: var(--bs-btn-color);
        text-align: center;
        text-decoration: none;
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
        border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
        border-radius: var(--bs-btn-border-radius);
        background-color: var(--bs-btn-bg);

      }
      .paginate_button{
        --bs-btn-padding-y: 0.25rem;
        --bs-btn-padding-x: 0.5rem;
        --bs-btn-font-size: 0.875rem;
        margin-right: 1%;
      }
.crossRolediv , .crossRolebtn{
   float: left;
}


.form-check .form-check-input {

  margin-left: unset !important;
}
.MuiTable-root thead tr th{
  font-family: "Poppins", sans-serif !important;
  /* text-align: center !important; */
}
.MuiTable-root tbody td{
  font-family: "Poppins", sans-serif !important;
  /* text-align: center !important; */
  padding: 14px 0px 0px 34px !important;
  /* width: 300px; */
  min-width: 175px;
  /* max-width: 100%; */
}
.MuiTypography-h6{
    color: #2782dd !important;
    font-weight: 400 !important;
    font-family: "Poppins", sans-serif !important;

}
.MuiFormControl-root{
  /* margin:10% !important;
  width:12% !important; */
  font-family: "Poppins", sans-serif !important;
}
.ico-sec{
  margin-left: -9%;
}
