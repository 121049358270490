.examStartEndTimeCardDiv {
  justify-content: center;
  display: flex;
  margin-top: 0.5rem
}

.examStartEndTimeCardDiv .examStartEndTimeCard {
  width: 50% !important;
  background: #F1F5FF !important;
  text-align: center !important;
}

.dateTimePickerDiv {
  margin-bottom: 0.5rem;
}

.react-datetime-picker__button {
  display: none !important;
}

.submitbtndiv button {
  float: right;
  margin-top: -30px;

}

.unUsualBehaviourCardDiv {
  justify-content: center;
  display: flex;
  margin-top: 0.5rem
}

.unUsualBehaviourCardDiv .card-box-style {
  width: auto !important;
  background: #F1F5FF !important;
  text-align: center !important;
}

.saveCancelBtn {
  margin-top: -10px;
  position: absolute;
}

.saveCancelBtn button {
  float: right;
}

.showmoreRow {
  width: 230% !important;

}

.showmoreRow label {
  color: #0d6efd;
  ;
}

.textend {
  text-align: end;
}

.textcenter {
  text-align: center;
}

.dropdown {
  position: absolute !important;
  display: contents !important;
}

.dropdown button {
  background: transparent !important;
  border: none !important;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-container {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: relative;
  max-width: 400px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background-color: transparent;
  font-size: 20px;
  cursor: pointer;
}

.popup-content {
  margin-top: 20px;
}

.multiSelectContainer {
  position: relative;
  text-align: left;
  width: 100%;
}

.multiSelectContainer,
.multiSelectContainer *,
.multiSelectContainer :after,
.multiSelectContainer :before {
  box-sizing: border-box;
}

.searchWrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  min-height: 22px;
  position: relative;
}

.multiSelectContainer {
  position: relative;
  text-align: left;
  width: 100%;
}

.chip {
  padding: 4px 10px;
  background: #0096fb;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 11px;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  line-height: 19px;
  color: #fff;
}

.multiSelectContainer input {
  border: 2px solid transparent !important;
  margin-top: 3px;
  background: transparent;
}

.margin-top-20 {
  margin-top: 20px;
}

#unUsualBehaviourCount {
  width: 50% !important;
}

.closeIcon {
  height: 13px;
  width: 13px;
  float: right;
  margin-left: 5px;
  cursor: pointer;
}

.examStartEndTimeCard {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.0196078431);
  border-radius: 10px;
  padding: 10px;
}

.multiSelectContainer input:focus {
  padding: 4px 10px;
  background: transparent;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 11px;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  line-height: 19px;
  color: black;
  border: 1px solid #ccc !important;
  outline: 0 !important;
}

.multiSelectContainer input:not(:focus) {
  padding: 4px 10px;
  background: transparent;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 11px;
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  line-height: 19px;
  color: black;
  border: 1px solid #ccc !important;
}



.quill-editor {
  /* Set a specific height or width for the editor */
  height: 100px;
  width: 760px;
}

.ql-editor {
  /* Enable word wrap */
  overflow-wrap: break-word;
  word-wrap: break-word;

  /* Ensure text wraps to the next line */
  word-break: break-word;
  white-space: normal;
}

.copybtn {
  justify-content: center;
  width: 85px;
  cursor: pointer;
  margin-left: -8px
}

.shardiv {
  margin-left: -60px
}

.quill-editor {
  margin-bottom: 15px;
}

.my-auto {
  margin-bottom: 20px !important;
  width: 750px !important;
}

.scrollable-container {
  height: 90px;
  overflow: hidden;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #888888 transparent;
}


.scrollable-container::-webkit-scrollbar {
  width: 6px;
}

.scrollable-container::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 3px;
}

.ql-editor {

  overflow: hidden !important;
  overflow: auto !important;
  scrollbar-width: thin !important;
  scrollbar-color: #888888 transparent !important;
}

.ql-editor::-webkit-scrollbar {
  width: 6px !important;
}

.ql-editor::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.ql-editor::-webkit-scrollbar-thumb {
  background-color: #888888 !important;
  border-radius: 3px !important;
}

.questionandsectionheader {
  margin: 15px 0px;
}

.categorytxtbox input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.device-content {


  background: #e4e4e4 !important;
}

.piechart canvas {
  height: 300px !important;
}

.parent {
  padding-left: 0px !important;
}

.shareCandidateGroupDropdown {
  width: 48% !important;
  margin-bottom: 2% !important;
}

/* .roundedsno{
  width: 50px;
  height: 50px;
  background-color: #2f92cb;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
} */

.tss-1cdcmys-MUIDataTable-responsiveBase {
  position: inherit !important;
}

.timelineReviewExam:before {
  content: none !important;
  position: absolute;
  top: 5px;
  bottom: 5px;
  width: 5px;
  background: #c0c3c6;
  left: 20%;
  margin-left: -2.5px
}

.malpracticearrow:after {
  border-left-color: #ffc107 !important
}

.malpracticearrow:before {
  border-right-color: #ffc107 !important
}

.total-button {
  background-color: #257ee1;
  ;
  /* Primary color, you can change this */
  color: #fff;
  /* Text color for better contrast */
  border: none;
  /* Remove the default button border */
  border-radius: 4px;
  /* Rounded corners for a more modern look */
  padding: 8px 16px;
  /* Adjust padding as needed */
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Smooth transition on hover */
}

.total-button:hover {
  background-color: #0056b3;
  /* Darker shade on hover */
}

.total-value {
  margin-left: 8px;
  /* Add some spacing between "Total" and the value */
  font-weight: bold;
  /* Make the value bold for emphasis */
}

.sectionbody {
  /* max-height: auto; */
  /* Set a max height for the modal body to enable vertical scrollbar */
  overflow-y: auto;
  /* Enable vertical scrollbar when content exceeds the height */
}




/* Custom scrollbar styles for WebKit-based browsers (Chrome, Safari) */
.sectionbody::-webkit-scrollbar {
  width: 6px;
}

.sectionbody::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

/* Custom scrollbar styles for Firefox */
.sectionbody::-moz-scrollbar {
  width: 6px;
}

.sectionbody::-moz-scrollbar-thumb {
  background-color: #888;
  border-radius: 3px;
}

.reviewreportcard p {
  font-weight: bold;
  margin-bottom: 0 !important;
}

.reviewreportcard hr {

  margin-top: 0 !important;
}

.reviewreportcardleft {
  padding-left: 7.5rem !important;
}

.reviewreportcardright {
  padding-right: 7.5rem !important;
}

.reviewreportcardleftprint {
  padding-left: 4.5rem !important;
}

.reviewreportcardrightprint {
  padding-right: 4.5rem !important;
}

.centerContent {
  text-align: center;
}

.react-datepicker-wrapper {
  width: 100% !important;
}