@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/*
Copy Right Footer Content Style
=====================================================*/
.footer-content {
  text-align: center;
  background: #EBF1FF 0% 0% no-repeat padding-box;
  border: 1px solid #CAD6F2;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 12px;
}
.footer-content p {
  font-size: 14px;
  font-weight: 600;
  color: #292D32;
}
.footer-content p a {
  color: #1765FD;
}