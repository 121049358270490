.overview-content-area label {
    font-size: 1rem !important;
}

.header-right-option span {
    font-size: 16px !important;
}

.overview-content-area span {
    font-size: 16px !important;
}